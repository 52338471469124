import { Component, EventEmitter, Input, OnInit } from '@angular/core';
import { KurentoService } from '../../../../services/kurento/kurento.service';
import { SoundMeter } from '../../../../libs/soundmeter';

@Component({
  selector: 'app-volume-meter',
  templateUrl: './volume-meter.component.html',
  styleUrls: ['./volume-meter.component.css']
})
export class VolumeMeterComponent implements OnInit {
  @Input() audioMuted: boolean;
  @Input() runVolumeMeter: EventEmitter<boolean>;

  meterRefresh: any;
  soundMeter: any;
  interpreterSilence: boolean;

  constructor() {
  }

  ngOnInit() {
    this.runVolumeMeter.subscribe((run: boolean) => {
      run ? this.initLocalVolumeMeter() : this.stopVolumeMeter();
    });
  }

  initLocalVolumeMeter() {
    this.stopVolumeMeter();
    if (!KurentoService.webRtcPeerInterpSnd) return;
    const instantMeter: HTMLMeterElement = document.querySelector('#instant meter');
    //const slowMeter: HTMLMeterElement = document.querySelector('#slow meter');
    const clipMeter: HTMLMeterElement = document.querySelector('#clip meter');

    //const instantValueDisplay: HTMLElement = document.querySelector('#instant .value');
    //const slowValueDisplay: HTMLElement = document.querySelector('#slow .value');
    //const clipValueDisplay: HTMLElement = document.querySelector('#clip .value');

    //let meterRefresh = null;

    const stream = KurentoService.webRtcPeerInterpSnd.getLocalStream();
    console.log('INTERPRETER LOCAL STREAM:', stream);

    //var mediaStream = audioContext.createMediaStreamSource(stream);
    //var volume = document.getElementById('volume');

    // Initializing soundMeter for interpreter mic
    const audioContext = new AudioContext();
    this.soundMeter = new SoundMeter(audioContext);

    // Starting soundMeter
    this.soundMeter.connectToSource(stream, e => {
      if (e) {
        //alert(e);
        this.soundMeter.stop();
        this.soundMeter = null;
        return;
      }
      //const soundMeter = this.soundMeter;

      this.meterRefresh = setInterval(() => {
        //console.log(' ???DEBUG??? connected to source! soundmeter:', (this.soundMeter || 'unknown'));
        //instantMeter.value = instantValueDisplay.innerText = this.soundMeter.instant.toFixed(2);
        instantMeter.value = this.soundMeter.instant.toFixed(2);
        if (instantMeter.value && (instantMeter.value > 0) && (instantMeter.value < 0.7)) {
          instantMeter.value = Number(((1 - instantMeter.value) * 3.3 * instantMeter.value).toFixed(2));
        }
        //slowMeter.value = slowValueDisplay.innerText = this.soundMeter.slow.toFixed(2);
        //slowMeter.value = this.soundMeter.slow.toFixed(2);
        //clipMeter.value = clipValueDisplay.innerText = this.soundMeter.clip;
        clipMeter.value = this.soundMeter.clip;

        this.interpreterSilence = this.soundMeter.silence;
      }, 100);
    });
  }

  stopVolumeMeter() {
    // Stopping soundMeter for interpreter mic
    if (this.soundMeter) {
      this.soundMeter.stop();
    }
    clearInterval(this.meterRefresh);
  }
}
