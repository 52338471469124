// Core imports
import { Component, HostListener, Input, OnDestroy, OnInit } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { fromEvent, Subscription } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
// Application imports
import { WebRtcPeer } from '../../../../libs/WebRtcPeer';
import { Event, EventStatus } from '../../../../models/Event';
import { User } from '../../../../models/User';
import { EventService } from '../../../../services/event/event.service';
import { KurentoService } from '../../../../services/kurento/kurento.service';
import { UserService } from '../../../../services/user/user.service';
import { FlashMessagesService } from '../../../../services/util/flash-messages.service';
import { VideoElementService } from '../../../../services/util/video-element.service';

declare var console: any;

@Component({
  selector: 'app-speaker',
  templateUrl: './speaker.component.html',
  styleUrls: ['./speaker.component.css']
})
export class KBroadcastComponent implements OnInit, OnDestroy {
  // eventId used to insert the component in another page passing the id as parameter
  @Input() eventId: string;
  // test is true when the event must be started on test mode, false for live mode
  @Input() test: false;

  sub: any;
  subscriptions = new Subscription();

  webRtcPeerSend: WebRtcPeer;
  webRtcPeerReceive: WebRtcPeer;

  user: User;
  // statsMap: Array<Object> = [];
  eventStarted: boolean;
  restartingEvent: boolean;
  eventInit: boolean;
  capturingScreen: boolean;
  programStarted: boolean;
  remoteDisabled: boolean = false;
  mode: string;
  remoteURL: string;
  captureURL: string;
  channelURL: SafeResourceUrl;
  startConferenceText = 'Start conference';
  startConferenceMsg = '';

  eCode: string;
  eventCodeEntered: boolean;
  submitted: boolean;
  eId: string;
  eData: Event; // NOT USED

  video: any;
  videoElement: any;
  constraints: any = { audio: { deviceId: null } };
  videoSelect: any;
  prevVSpace = 8.32;
  onResize = fromEvent(window, 'resize');
  private readonly startingMediaServerMsg = '  Starting the media server. It will require approximately 3 minutes, please wait…';
  private readonly restartingMediaServerMsg = '  Restarting the media server. It will require approximately 30 seconds, please wait…';

  constructor(
    private sanitizer: DomSanitizer,
    private route: ActivatedRoute,
    private eventServ: EventService,
    private userServ: UserService,
    private flashMessagesServ: FlashMessagesService,
    private videoElementServ: VideoElementService
  ) {
    // this.subscriptions.add(KurentoService.statsMapChange.subscribe((value) => {
    //   //this.statsMap = value;
    //   //console.log('KBroadcastComponent:statsMapChange: statsMap:', this.statsMap);
    //   //this.speakerStreamingToggle();
    // }));

    this.subscriptions.add(KurentoService.streamWorkingChange.subscribe((value) => {
      if (value) {
        // TODO green light indicator
        return;
      } else {
        this.flashMessagesServ.error('Cannot start program. Check source resolution (suggested is up to 1280x720) and try again…');
        this.stop();
      }
    }));

    // COMPILE
    /*
    this.subscriptions.add(this.onResize
    .pipe(debounceTime(400))
    .subscribe((event) => { this.vbsPositioning(); }));
    */
  }

  vbsPositioning() {
    if (this.eventStarted) {
      const vbsHeight: any = document.getElementById('video-big-speaker').offsetHeight || 0;
      const vcHeight: any = document.getElementById('video-container').offsetHeight || 0;
      const pcHeight: any = document.getElementById('program-controls').offsetHeight || 0;
      const vSpace: any = Math.round((vbsHeight - vcHeight - 8.32 - pcHeight) / 2 * 100) / 100;
      console.log('???DEBUG??? KBroadcastComponent:vbsPositioning: vSpace:', vSpace, 'vbsHeight:', vbsHeight, 'vcHeight:', vcHeight, 'pcHeight:', pcHeight);

      if (vSpace === this.prevVSpace) {
        // Return in case of NO changes OR store the new value into prevVSpace
        return;
      } else {
        // Set prevVSpace with the new value
        this.prevVSpace = vSpace;
      }

      if (vSpace > 8.32) {
        // Set the new value
        document.getElementById('video-big-speaker').style.paddingTop = vSpace + 'px';
      } else {
        // Restore default value
        document.getElementById('video-big-speaker').style.paddingTop = '8.32px';
      }
    }
  }

  ngOnInit() {
    KurentoService.checkEventStatus();
    if (this.eventServ.getCurrentEvent()) {
      if (this.eventServ.getCurrentEvent().status === EventStatus.STARTING) {
        this.eventInit = true;
      }

      // Disable remote
      if (this.eventServ.getCurrentEvent().audience.webPortal.available) {
        this.remoteDisabled = true;
      }
    }

    this.subscriptions.add(KurentoService.eventStartedChange.subscribe((value) => {
      if (!this.restartingEvent) {
        if (!this.eventStarted && value) {
          this.eventInit = false;
        } else if (this.eventStarted && !value) {
          this.stopEventResponse();
        }

        this.eventStarted = value;
      }

      if (this.eventStarted) this.vbsPositioning();

      if (KurentoService.eventStatus.starting || KurentoService.eventStatus.restarting) {
        this.eventInit = true;
        this.startConferenceMsg = KurentoService.eventStatus.starting ? this.startingMediaServerMsg : this.restartingMediaServerMsg;
      }

      // Loads user data
      this.subscriptions.add(this.userServ.userChange.subscribe((user) => {
        if (user) {
          // Registers the interpreter in the proper language pair
          this.user = user;
          KurentoService.speakerJoin(this.eventId, user._id);
        }
      }));
    }));

    this.subscriptions.add(KurentoService.restartEventRejectedChange.subscribe((error) => {
      const options = {
        showCloseBtn: true,
        timeout: 15000
      }
      this.flashMessagesServ.error('Restart event failed. Please contact HelpDesk by using the instant chatting widget button available at the bottom right corner', options);
      let msg = error.message;
      if (typeof msg === 'object') {
        msg = msg.message;
      }

      if (msg) {
        this.flashMessagesServ.error(msg, options);
      }
    }));

    this.subscriptions.add(KurentoService.eventRestartedByServerChange.subscribe((restarted) => {
      if (restarted) {
        this.setVariablesForRestartingEvent();
      }
    }));

    this.sub = this.route.queryParams.subscribe(params => {
      if (params['event'] || this.eventId) {
        this.eventCodeEntered = true;
      }

      // sets the event param in the proper service attribute or use the default event
      KurentoService.eventId = params['event'] || this.eventId || '5a8f837473e4f408b03eda96';

      this.channelURL = KurentoService.getProviderURL(params, this.sanitizer);
    });

    if (this.eventCodeEntered) {
      KurentoService.startWsIfNotRunning();

      this.videoElement = document.getElementById('video-speaker');
      this.videoSelect = document.getElementById('videoSource');

      // Remote source default values
      this.mode = 'local';
      this.remoteURL = 'https://webrtc.github.io/samples/src/video/chrome.webm';
      this.captureURL = '';

      //console.warn('KBroadcastComponent:eventStartedChange: enumerateDevices:', navigator.mediaDevices.enumerateDevices());
      this.loadSources();
    } else {
      this.eCode = '';
    }

    // Hide top header and navbar, footer, and Intercom widget
    document.getElementById('topHeader').classList.add('d-none');
    document.getElementById('topNavbar').classList.add('d-none');
    document.getElementById('f').classList.add('d-none');
    /* TODO: Shut down or hide Intercom widget
    let nRetries = 0;
    const hIWI = setInterval(() => {
      nRetries++;
      let stopInt = false;
      if (document.getElementsByClassName('intercom-lightweight-app').length > 0) {
        for (let c = 0; c < document.getElementsByClassName('intercom-lightweight-app').length; c++) {
          document.getElementsByClassName('intercom-lightweight-app')[c].classList.add('d-none');
          stopInt = true;
        }
      }
      if (document.getElementById('intercom-container') !== null) {
        document.getElementById('intercom-container').classList.add('d-none');
        stopInt = true;
      }
      if (stopInt || (nRetries > 3)) {
        clearInterval(hIWI);
      }
    }, 7500);
    */

    navigator.mediaDevices.ondevicechange = () => {
      this.loadSources();
    }

    this.subscriptions.add(this.onResize
      .pipe(debounceTime(400))
      .subscribe(() => {
        this.vbsPositioning();
      }));
  }

  // Show eventCode errors
  public showError(input): boolean {
    return (input.invalid && (input.touched || this.submitted));
  }

  // Submit the event code and handle the response
  public onSubmit(enterEventCodeForm) {
    this.submitted = true;

    // Form validation
    if (enterEventCodeForm.invalid) {
      return;
    }

    // console.log('KBroadcastComponent:onSubmit: eCode:', this.eCode);
    //this.eId = this.route.snapshot.paramMap.get('event');
    this.eventServ.getByCode(this.eCode, (err, data) => {
      if (err) {
        console.error('KBroadcastComponent:getByCode: ERROR! err:', err);
      }

      this.eId = '5a8f837473e4f408b03eda96';
      if (data && data._id) {
        this.eId = data._id.toString();
        this.eData = data; // NOT USED
      }

      // TODO: To use the two lines below (and remove the 3rd one) we need to move Kurento stuff outside ngOnInit
      //this.eventCodeEntered = true;
      //this.router.navigate(['/speaker', { event: this.eId }]);
      location.replace('/speaker?event=' + this.eId);
    });
  }

  changeSource() {
    const videoSource = this.videoSelect.value;
    if (videoSource.includes('screen')) {
      this.capturingScreen = true;
    } else {
      if (this.capturingScreen === true) this.capturingScreen = false;
    }
  }

  changeMode(mode: string) {
    this.mode = mode;
    console.log(
      'KBroadcastComponent:changeMode: mode:', mode +
      (this.remoteURL ? ', remoteURL: ' + this.remoteURL : '')
    );
  }

  presenter() {
    this.videoElementServ.showSpinner(this.videoElement);

    const videoSource = this.videoSelect.value;

    if (!videoSource) {
      this.videoElementServ.hideSpinner(this.videoElement);
    }

    this.constraints.video = videoSource ? { deviceId: videoSource ? { exact: videoSource } : undefined } : false;

    if (videoSource.includes('screen')) {
      this.constraints.video = {};
    }
    if (this.mode === 'remote') {
      this.constraints.video = true;
      this.constraints.audio = true;
    }

    console.log(
      'KBroadcastComponent:presenter: videoSource:', videoSource +
      ', constraints:', this.constraints
    );

    KurentoService.speakerConstraints = {
      audio: !!this.constraints.audio,
      video: !!this.constraints.video
    };

    const options: any = {
      localVideo: null,
      remoteVideo: null,
      onicecandidate: KurentoService.onIceCandidate,
      mediaConstraints: this.constraints,
      sendSource: 'webcam'
      /*
      sendSource: 'screen'
      sendSource: 'webcam',
      dataChannelConfig: {
        id : KurentoService.eventId,
        onmessage : onMessage,
        onopen : onOpen,
        onclose : onClosed,
        onbufferedamountlow : onbufferedamountlow,
        onerror : onerror
      }
      */
    };

    KurentoService.mode = this.mode;
    KurentoService.remoteURL = this.remoteURL;
    if (videoSource.includes('screen')) {
      options.sendSource = 'screen';
    }

    this.disposeWebRtcPeers();

    // Starts local or remote streaming
    if (this.mode === 'local' && (this.constraints.audio || this.constraints.video)) { // 'local' mode
      if (options.sendSource === 'screen') { // 'local' mode, 'screen' source
        console.log('KBroadcastComponent:presenter: SCREEN CAPTURE MODE');
        options.mediaConstraints.audio.echoCancellation = false;
        options.mediaConstraints.audio.autoGainControl = false;
        options.mediaConstraints.audio.noiseSuppression = false;
        options.mediaConstraints.video.width = { ideal: 1280, max: 1280 };
        options.mediaConstraints.video.height = { ideal: 720 };
        options.mediaConstraints.video.aspectRatio = { ideal: 1.7777777778 };
        options.mediaConstraints.video.frameRate = { ideal: 30 };
        //@ts-ignore
        navigator.mediaDevices.getDisplayMedia(options.mediaConstraints)
          .then(stream => {
            options.videoStream = stream;
            options.localVideo = this.videoElement;
            KurentoService.webRtcPeerSpeaker = this.webRtcPeerSend = WebRtcPeer.Sendonly(options, function(error: any) {
              if (error) {
                console.error('KBroadcastComponent:presenter: ERROR! error:', error);
                throw new Error(error);
              }

              this.generateOffer(KurentoService.onOfferPresenter);
            });

            this.programStarted = true;
          })
          .catch(err => {
            this.flashMessagesServ.error('Program not started properly…. Please STOP and START program again.');
            console.error('KBroadcastComponent:presenter: ERROR! err:', err)
          });
      } else { //  'local' moded, NON 'screen' source
        console.log('KBroadcastComponent:presenter: LOCAL MODE');
        options.localVideo = this.videoElement;
        if (options.mediaConstraints) {
          if (options.mediaConstraints.audio) {
            options.mediaConstraints.audio.echoCancellation = false;
            options.mediaConstraints.audio.autoGainControl = false;
            options.mediaConstraints.audio.noiseSuppression = false;
          }
          if (options.mediaConstraints.video) {
            options.mediaConstraints.video.width = { ideal: 1280 };
            options.mediaConstraints.video.height = { ideal: 720 };
            options.mediaConstraints.video.aspectRatio = { ideal: 1.7777777778 };
            options.mediaConstraints.video.frameRate = { ideal: 30 };
          }
        }
        KurentoService.webRtcPeerSpeaker = this.webRtcPeerSend = WebRtcPeer.Sendonly(options, function(error: any) {
          if (error) {
            throw new Error(error);
          }

          this.generateOffer(KurentoService.onOfferPresenter);
        });

        this.programStarted = true;
      }
    } else if (this.mode === 'remote') { // 'remote' mode
      console.log('KBroadcastComponent:presenter: REMOTE MODE');
      options.remoteVideo = this.videoElement;
      KurentoService.webRtcPeerSpeaker = this.webRtcPeerReceive = WebRtcPeer.Recvonly(options, function(error) {
        if (error) {
          throw new Error(error);
        }
        this.generateOffer(KurentoService.onOfferPresenter);
      });

      this.programStarted = true;
    } else {
      this.flashMessagesServ.error('Cannot start program. Check settings on the right side and try again…');
      this.videoElementServ.hideSpinner(this.videoElement);
    }
  }

  stop() {
    this.dispose();
    KurentoService.onStop();
    this.programStarted = false;
    if (this.capturingScreen === true) this.capturingScreen = false;
    this.loadSources();
  }

  startEvent() {
    KurentoService.startEvent();
    this.eventInit = true;
    this.startConferenceText = 'Starting conference…';
    this.startConferenceMsg = this.startingMediaServerMsg;
  }

  stopEvent() {
    KurentoService.stopEvent();
  }

  restartEvent() {
    this.setVariablesForRestartingEvent();
    // send message to server to restart event
    KurentoService.restartEvent();
  }

  stopEventResponse() {
    this.stop();
    this.startConferenceText = 'Start conference';
    this.eventInit = false;
  }

  dispose() {
    this.disposeWebRtcPeers();
    this.videoElementServ.hideSpinner(this.videoElement);
    console.log('KBroadcastComponent:dispose: WebRTC peer disposed!');
  }

  openCaptureWindow() {
    //Popup window for capture
    try {
      new URL(this.captureURL);
      window.open(
        this.captureURL,
        'capturewin',
        'top=0,left=' + ((screen.width - 960) > 0 ? screen.width - 960 : 0) + ',width=960,height=544'
      );
      // PREV:  'top=0,left=' + ((screen.width - 1280) > 0 ? screen.width - 1280 : 0) + ',width=1280,height=724'
      // 16:9 => 640×360 (nHD), 960×540 (qHD), 1280×720 (HD), 1600×900 (HD+), 1920×1080 (FHD), 2560×1440 (QHD), 3840×2160 (4K UHD), 7680×4320 (8K UHD)
      //  4:3 => 640×480 (VGA), 768×576 (PAL), 800×600 (SVGA), 1024×768 (XGA)
    } catch {
      return this.flashMessagesServ.error('Wrong URL format');
    }
  }

  gotDevices(deviceInfos) {
    console.log('KBroadcastComponent:gotDevices: deviceInfos', deviceInfos);
    const selectedVideo = this.videoSelect.value;
    // check if program is started and video device was disconnected
    if (this.programStarted && this.videoSelect.value && (this.videoSelect.value !== 'screenvid') && !deviceInfos.filter(d => d.deviceId === this.videoSelect.value).length) {
      this.flashMessagesServ.warning('The selected video device was disconnected. Stop the program and select a new video device', {
        showCloseBtn: true,
        timeout: 15000
      });
    }

    // Handles being called several times to update labels. Preserve values.
    const value = this.videoSelect.value;
    while (this.videoSelect.firstChild) {
      this.videoSelect.removeChild(this.videoSelect.firstChild);
    }

    for (let i = 0; i !== deviceInfos.length; ++i) {
      const deviceInfo = deviceInfos[i];
      const option = document.createElement('option');
      option.value = deviceInfo.deviceId;
      if (deviceInfo.kind === 'videoinput') {
        option.text = deviceInfo.label || `camera ${ this.videoSelect.length + 1 }`;
        this.videoSelect.appendChild(option);
      } else {
        console.log('KBroadcastComponent:gotDevices: Some other kind of source/device: ', deviceInfo);
      }
    }
    if (Array.prototype.slice.call(this.videoSelect.childNodes).some(n => n.value === value)) {
      this.videoSelect.value = value;
    }

    // Screen capture option creation
    const screenVideo = document.createElement('option');
    screenVideo.value = 'screenvid';
    screenVideo.text = 'Ablio Capture';

    // None option creation
    const optionVideo = document.createElement('option');
    optionVideo.value = '';
    optionVideo.text = 'None';

    this.videoSelect.appendChild(screenVideo);

    this.videoSelect.appendChild(optionVideo);
    if (selectedVideo === '' || selectedVideo === 'screenvid' || deviceInfos.find(d => d.deviceId === selectedVideo)) {
      this.videoSelect.value = selectedVideo;
    }
  }

  /*
  stats() {
    if (KurentoService.webRtcPeer) {
      const peerConnection = KurentoService.webRtcPeer.peerConnection;

      peerConnection.getStats((stats) => {
        //console.log('KBroadcastComponent:stats: STATS:');

        const results = stats.result();
        for (let i = 0; i < results.length; i++) {
          const res = results[i];
          if (res.type !== 'ssrc') {
            continue;
          }

          // Publish it to be compliant with W3C stats draft
          this.statsMap[i] = {
            timeStamp: res.timestamp,
            // StreamStats below
            associateStatsId: res.id,
            codecId: '--',
            firCount: res.stat('googFirsReceived'),
            isRemote: false,
            mediaTrackId: res.stat('googTrackId'),
            nackCount: res.stat('googNacksReceived'),
            pliCount: res.stat('googPlisReceived'),
            sliCount: 0,
            ssrc: res.stat('ssrc'),
            transportId: res.stat('transportId'),
            // Specific outbound below
            bytesSent: res.stat('bytesSent'),
            packetsSent: res.stat('packetsSent'),
            roundTripTime: res.stat('googRtt'),
            packetsLost: res.stat('packetsLost'),
            targetBitrate: '??',
            remb: '??'
          };
        }
        console.log('KBroadcastComponent:stats: statsMap', this.statsMap);
        //return callback('Error: could not find ssrc type on track stats', null);
      }, this.statsMap);
    }
    // Webrtc endpoints stats
    KurentoService.endpointStats(KurentoService.eventId, 'speaker', '', 0);
  }
  */

  loadSources() {
    let askPermission = true;
    if (navigator.mediaDevices.ondevicechange !== null) {
      askPermission = false; // already asked permission
    }
    navigator.mediaDevices.getUserMedia({
      video: askPermission
    }).then(stream => {
      // immediately turn off camera after asking for permission. Otherwise, the user might think is already recording
      stream.getVideoTracks().forEach(track => track.stop());
    }).finally(() => {
      navigator.mediaDevices.enumerateDevices()
        .then((devices) => {
          this.gotDevices(devices);
          this.changeSource();
        })
        .catch((error) => {
          console.error('KBroadcastComponent:loadSources: ERROR! error:', error);
        });
    });
  }

  onInputDeviceIdChange = (deviceId) => {
    this.constraints.audio.deviceId = deviceId;
  }

  @HostListener('window:beforeunload', ['$event'])
  beforeunloadHandler() {
    this.stop();
  }

  ngOnDestroy() {
    this.stop();

    // Show top header and navbar, footer, and Intercom widget again
    document.getElementById('topHeader').classList.remove('d-none');
    document.getElementById('topNavbar').classList.remove('d-none');
    document.getElementById('f').classList.remove('d-none');
    /* TODO: Start up or show Intercom widget again
    if (document.getElementsByClassName('intercom-lightweight-app').length > 0) {
      for (let c = 0; c < document.getElementsByClassName('intercom-lightweight-app').length; c++) {
        document.getElementsByClassName('intercom-lightweight-app')[c].classList.remove('d-none');
      }
    }
    if (document.getElementById('intercom-container') !== null) {
      document.getElementById('intercom-container').classList.remove('d-none');
    }
    */

    this.subscriptions.unsubscribe();
  }

  private disposeWebRtcPeers() {
    KurentoService.disposeWebRtcPeer(this.webRtcPeerSend);
    KurentoService.disposeWebRtcPeer(this.webRtcPeerReceive);
  }

  /*
  // COMPILE but NOT debounceTime
  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.vbsPositioning();
  }
  */

  /*
  // COMPILE
  onResize = fromEvent(window, 'resize')
  .pipe( debounceTime(400) )
  .subscribe((event) => { this.vbsPositioning(); });

  // TS1068: Unexpected token. A constructor, method, accessor, or property was expected.
  this.subscriptions.add(fromEvent(window, 'resize').pipe(debounceTime(400)).subscribe((event) => { this.vbsPositioning(); });

  // TS1068: Unexpected token. A constructor, method, accessor, or property was expected.
  this.subscriptions.add(onResize = fromEvent(window, 'resize').pipe(debounceTime(400)).subscribe((event) => { this.vbsPositioning(); });

  // TS1068: Unexpected token. A constructor, method, accessor, or property was expected.
  onResize = fromEvent(window, 'resize').pipe(debounceTime(400));
  this.subscriptions.add(this.onResize.subscribe((event) => { this.vbsPositioning(); });

  // TS1068: Unexpected token. A constructor, method, accessor, or property was expected.
  onResize = fromEvent(window, 'resize');
  this.subscriptions.add(this.onResize
  .pipe(debounceTime(400))
  .subscribe((event) => { this.vbsPositioning(); }));
  */

  private setVariablesForRestartingEvent() {
    this.restartingEvent = true;
    setTimeout(() => {
      // used for stop listening event started changes for 5 seconds to avoid status confusion, event will be restarting
      this.restartingEvent = false;
    }, 5000);
    this.stop();
    // Event restarted, notify other components (audience component)
    KurentoService.eventRestartedChange.next(true);
    this.eventStarted = false;
    this.eventInit = true;
    this.startConferenceText = 'Restarting conference…';
    this.startConferenceMsg = this.restartingMediaServerMsg;
  }
}
