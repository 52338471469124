export class WebRtcPeer {
  peerConnection: any;
  remoteVideo: any;
  localVideo: any;

  private configuration = {
    // 5 max
    iceServers: [
      // TURN UDP
      {
        urls: 'turn:turn.ablioaudience.com:3478?transport=udp',
        username: 'ablio',
        credential: 'ablio'
      },
      // TURN TCP
      {
        urls: 'turn:turn.ablioaudience.com:3478?transport=tcp',
        username: 'ablio',
        credential: 'ablio'
      },
      // STUNs
      {
        urls: [
          'stun:turn.ablioaudience.com:3478',
          'stun:stun.l.google.com:19302',
          //'stun:stun1.l.google.com:19302',
          //'stun:stun2.l.google.com:19302',
          //'stun:stun3.l.google.com:19302',
          'stun:stun4.l.google.com:19302'
        ]
      }
    ]
  };
  private DEFAULT_CONSTRAINTS = {
    audio: true,
    video: {
      width: { ideal: 1280 },
      height: { ideal: 720 },
      aspectRatio: { ideal: 1.7777777778 },
      frameRate: { ideal: 30 }
    }
  };
  private candidatesQueueOut = [];
  private candidategatheringdone = false;
  private options: any;
  private readonly callback: any;
  private readonly mode: string;

  private constructor(mode: string, options: any, callback: any) {
    this.mode = mode;
    this.options = options;
    this.remoteVideo = options.remoteVideo;
    this.localVideo = options.localVideo;
    this.options.sendSource = options.sendSource || 'webcam';
    this.callback = callback;
    this.peerConnection = new RTCPeerConnection(this.configuration);

    // Calling start method
    if (mode !== 'recvonly' && !this.options.videoStream && !this.options.audioStream) {
      if (this.options.sendSource === 'webcam') {
        if (this.options.mediaConstraints === undefined) {
          this.options.mediaConstraints = this.DEFAULT_CONSTRAINTS;
        }
        navigator.mediaDevices.getUserMedia(this.options.mediaConstraints).then((
          stream) => {
          this.options.videoStream = stream;

          this.start();
        }).catch(this.callback);
      }
    } else {
      setTimeout(() => {
        this.start();
      }, 0);
    }

    this.peerConnection.addEventListener('icecandidate', event => {
      const candidate = event.candidate;
      this.candidatesQueueOut.push(candidate);
      if (!candidate) {
        this.candidategatheringdone = true;
      }
    });

    this.peerConnection.addEventListener('connectionstatechange', event => {
      if (this.peerConnection.connectionState === 'connected') {
        console.log('Peers connected: ', event);
      }
    });
  }

  static Recvonly(options: any, callback: any) {
    return new WebRtcPeer('recvonly', options, callback);
  }

  static Sendonly(options: any, callback: any) {
    return new WebRtcPeer('sendonly', options, callback);
  }

  private static logError(error?) {
    if (error) {
      console.error(error);
    }
  }

  getLocalStream() {
    if (this.peerConnection) {
      const stream = new MediaStream();
      this.peerConnection.getSenders().forEach((sender) => {
        if (sender.track !== null) stream.addTrack(sender.track);
      });
      return stream;
    }
  }

  getRemoteStream() {
    if (this.peerConnection) {
      const stream = new MediaStream();
      this.peerConnection.getReceivers().forEach((sender) => {
        if (sender.track !== null) stream.addTrack(sender.track);
      });
      return stream;
    }
  }

  muteUnmuteAudio(mute: boolean) {
    if (!this.peerConnection) {
      return;
    }
    this.getLocalStream().getAudioTracks().forEach(track => track.enabled = !mute);
  }

  /**
   * Callback function invoked when an ICE candidate is received. Developers are expected to invoke this function in
   * order to complete the SDP negotiation.
   *
   * @function module:WebRtcPeer.addIceCandidate
   *
   * @param iceCandidate - Literal object with the ICE candidate description
   * @param callback - Called when the ICE candidate has been added.
   */
  addIceCandidate(iceCandidate, callback = WebRtcPeer.logError) {
    const candidate = new RTCIceCandidate(iceCandidate);

    console.log('Remote ICE candidate received', iceCandidate);
    const addIceCandidateFunction = this.bufferizeCandidates(this.peerConnection, callback);
    addIceCandidateFunction(candidate, callback);
  }

  /**
   * Function invoked after RTCPeerConnection was created. Developers are expected to invoke this function in order to
   * create the offer and continue the SDP negotiation by passing a callback function that will send the offer.
   *
   * @function module:WebRtcPeer.generateOffer
   *
   * @param callback - Called with parameters (error, sdpOffer) after creating the sdp offer and setting the local
   * description.
   */
  generateOffer(callback) {
    if (this.mode === 'recvonly') {
      /* Add reception tracks on the RTCPeerConnection. Send tracks are unconditionally added to "sendonly" and
       * "sendrecv" modes, in the constructor's "start()" method, but nothing is done for "recvonly". Here, we add new
       * transceivers to receive audio and/or video, so the SDP Offer that will be generated by the PC includes these
       * medias with the "a=recvonly" attribute.
       */
      const mc = this.options.mediaConstraints;
      const useAudio = (mc && typeof mc.audio === 'boolean') ? mc.audio : true;
      const useVideo = (mc && typeof mc.video === 'boolean') ? mc.video : true;

      if (useAudio) {
        this.peerConnection.addTransceiver('audio', {
          direction: 'recvonly'
        });
      }

      if (useVideo) {
        this.peerConnection.addTransceiver('video', {
          direction: 'recvonly'
        });
      }
    } else if (this.mode === 'sendonly') {
      /* The constructor's "start()" method already added any available track, which by default creates Transceiver
       * with "sendrecv" direction.
       * Here, we set all transceivers to only send audio and/or video, so the SDP Offer that will be generated by the
       * PC includes these medias with the "a=sendonly" attribute.
       */
      this.peerConnection.getTransceivers().forEach((transceiver) => {
        transceiver.direction = 'sendonly';
      });
    }

    this.peerConnection.createOffer()
      .then((offer) => {
        console.log('Created SDP offer');
        return this.peerConnection.setLocalDescription(offer);
      })
      .then(() => {
        const localDescription = this.peerConnection.localDescription;
        console.log('Local description set');
        callback(null, localDescription.sdp, this.processAnswer);
      })
      .catch(callback);
  }

  showLocalVideo() {
    this.localVideo.srcObject = this.options.videoStream;
    this.localVideo.muted = true;

    this.localVideo.srcObject = this.options.videoStream;
  }

  /**
   * Callback function invoked when an SDP answer is received. Developers are expected to invoke this function in order
   * to complete the SDP negotiation.
   *
   * @function module:WebRtcPeer.processAnswer
   *
   * @param sdpAnswer - Description of sdpAnswer
   * @param callback - Invoked after the SDP answer is processed, or there is an error.
   */
  processAnswer(sdpAnswer, callback = WebRtcPeer.logError) {
    const answer = new RTCSessionDescription({
      type: 'answer',
      sdp: sdpAnswer
    });

    console.log('SDP answer received, setting remote description');

    if (this.peerConnection.signalingState === 'closed') {
      return callback('PeerConnection is closed');
    }

    this.peerConnection.setRemoteDescription(answer).then(() => {
      console.log('Set remote description successfully');
      this.setRemoteVideo();
      callback();
    }).catch(e => {
      console.log('Error setting remote description');
      callback(e);
    });
  }

  /**
   * Callback function invoked when an SDP offer is received. Developers are expected to invoke this function in order
   * to complete the SDP negotiation.
   *
   * @function module:WebRtcPeer.processOffer
   *
   * @param sdpOffer - Description of sdpOffer
   * @param callback - Called when the remote description has been set successfully.
   */
  processOffer(sdpOffer, callback) {
    const offer = new RTCSessionDescription({
      type: 'offer',
      sdp: sdpOffer
    });

    console.log('SDP offer received, setting remote description');

    if (this.peerConnection.signalingState === 'closed') {
      return callback('PeerConnection is closed');
    }

    this.peerConnection.setRemoteDescription(offer).then(() => {
      return this.setRemoteVideo();
    }).then(() => {
      return this.peerConnection.createAnswer();
    }).then((answer) => {
      console.log('Created SDP answer');
      return this.peerConnection.setLocalDescription(answer);
    }).then(() => {
      const localDescription = this.peerConnection.localDescription;
      console.log('Local description set');
      callback(null, localDescription.sdp);
    }).catch(callback);
  }

  dispose() {
    console.log('Disposing WebRtcPeer');

    let pc = this.peerConnection;
    try {
      if (pc) {
        if (pc.signalingState === 'closed') {
          return;
        }

        pc.getLocalStreams().forEach(stream => {
          stream.getTracks().forEach(track => {
            if (track.stop) {
              track.stop();
            }
          });
        });

        try {
          if (this.options.videoStream) {
            pc.removeStream(this.options.videoStream);
          }
          if (this.options.audioStream) {
            pc.removeStream(this.options.audioStream);
          }
        } catch (err) {
          console.warn('Exception trying to remove stream from peerConnection: ' + err);
        }

        pc.close();
        pc = null;
      }
    } catch (err) {
      console.warn('Exception disposing webrtc peer: ' + err);
    }
  }

  private bufferizeCandidates(peerConnection, onerror) {
    const candidatesQueue = [];

    peerConnection.addEventListener('signalingstatechange', () => {
      if (peerConnection.signalingState === 'stable') {
        while (candidatesQueue.length) {
          const entry = candidatesQueue.shift();
          peerConnection.addIceCandidate(entry.candidate, entry.callback, entry.callback);
        }
      }
    });

    return (candidate, callback) => {
      callback = callback || onerror;
      switch (peerConnection.signalingState) {
        case 'closed':
          callback(new Error('PeerConnection object is closed'));
          break;
        case 'stable':
          if (peerConnection.remoteDescription) {
            peerConnection.addIceCandidate(candidate, callback, callback);
          }
          break;
        default:
          candidatesQueue.push({
            candidate: candidate,
            callback: callback
          });
      }
    };
  }

  private videoIsPlaying(video: any) {
    return video && video.currentTime > 0 && !video.paused && !video.ended && video.readyState > video.HAVE_CURRENT_DATA;
  }

  private setRemoteVideo() {
    if (this.remoteVideo) {
      if (this.videoIsPlaying(this.remoteVideo)) {
        this.remoteVideo.pause();
      }

      const stream = this.getRemoteStream();
      this.remoteVideo.srcObject = stream;
      console.log('Remote stream:', stream);
      this.remoteVideo.load();
    }
  }

  /**
   * This function creates the RTCPeerConnection object taking into account the properties received in the constructor.
   * It starts the SDP negotiation process: generates the SDP offer and invokes the onsdpoffer callback. This callback
   * is expected to send the SDP offer, in order to obtain an SDP answer from another peer.
   */
  private start() {
    if (this.peerConnection && this.peerConnection.signalingState === 'closed') {
      this.callback(
        'The peer connection object is in "closed" state. This is most likely due to an invocation of the dispose method before accepting in the dialogue'
      );
    }

    if (this.options.videoStream && this.localVideo) {
      this.showLocalVideo();
    }

    if (this.options.videoStream) {
      this.options.videoStream.getTracks().forEach((track) => {
        this.peerConnection.addTrack(track, this.options.videoStream);
      });
    }

    if (this.options.audioStream) {
      this.options.audioStream.getTracks().forEach((track) => {
        this.peerConnection.addTrack(track, this.options.audioStream);
      });
    }

    this.callback();
  }
}
