import { Injectable } from '@angular/core';

declare var $: any;

@Injectable({
  providedIn: 'root'
})
export class SeoService {
  private readonly seoDataMap;
/*
<html lang="en">
<title>AblioConference :: simultaneous translation systems</title>
<meta name="description" content="AblioConference: the complete simultaneous interpretation management system for conferences and events.">
<!-- description: no longer than 155 characters. -->
<meta property="og:description" content="AblioConference: the complete simultaneous interpretation management system for conferences and events.">
<meta property="og:image" content="https://ablioconference.com/media/home-animation-end.jpg">
<meta property="og:locale" content="en_US">
<!-- title: maximum length 60 characters. -->
<meta property="og:title" content="AblioConference :: simultaneous translation systems">
<meta property="og:url" content="https://ablioconference.com/home">
<!-- card: summary_large_image|summary OR none (without image) -->
<meta name="twitter:card" content="summary_large_image">
<!-- image:alt only if twitter:card = summary_large_image & og:image NOT null -->
<meta name="twitter:image:alt" content="AblioConference :: simultaneous translation systems">
<script type="application/ld+json">{"@context":"http://schema.org","@type":"Organization","name":"Ablio","legalName":"Ablio LLC","url":"https://ablioconference.com/home","logo":"https://ablioconference.com/images/brand/logo-512x69.png","foundingDate":"2014","founders":[{"@type":"Person","name":"Giulio Monaco"},{"@type":"Person","name":"Jose Fresco Benaim"},{"@type":"Person","name":"Piergiorgio Fiandanese"}],"contactPoint":[{"@type":"ContactPoint","telephone":"+1 805-601-8224","contactType":"customer service"},{"@type":"ContactPoint","telephone":"+39 06 9480 7005","contactType":"customer service","areaServed":["IT"],"availableLanguage":["Italian","English"]}]}</script>

https://ablioconference.com/
https://ablioconference.com/ablioconference
https://ablioconference.com/about
https://ablioconference.com/advantages
https://ablioconference.com/contact
#https://ablioconference.com/docs
https://ablioconference.com/early-adopters-program
#https://ablioconference.com/events-organizers
https://ablioconference.com/faq
https://ablioconference.com/home
https://ablioconference.com/how-it-works
https://ablioconference.com/language-service-providers
https://ablioconference.com/pricing
https://ablioconference.com/privacy-policy
#https://ablioconference.com/quality-standards
#https://ablioconference.com/resellers-network
https://ablioconference.com/resellers-program
https://ablioconference.com/ablioconference-educational
https://ablioconference.com/signin
https://ablioconference.com/signup
https://ablioconference.com/sitemap
https://ablioconference.com/technical-service-providers
https://ablioconference.com/terms-and-conditions
#https://ablioconference.com/tour
#https://ablioconference.com/venues
*/

  constructor() {
    this.seoDataMap = {
      'allotherpages': {
        // generates <meta name="[key]" content="[value]">
        meta: {
          // title: maximum length 60 characters
          title: 'simultaneous translation systems',
          // description: no longer than 155 characters
          description: 'A pay-per-use service for events, meetings, seminars, and conferences.',
          // Twitter Card
          'twitter:card': 'summary_large_image',
          // only if twitter:card = summary_large_image & og:image != null
          'twitter:image:alt': 'AblioConference logo'
        },
        // generates <meta property="[key]" content="[value]">
        props: {
          // Open Graph (Facebook)
          'og:image': 'https://ablioconference.com/images/brand/logo-512x69.png',
          'og:type': 'website',
          'og:url': 'https://ablioconference.com/'
        },
        enOnly: true,
        // title: maximum length 60 characters
        titleEs: 'sistemas de interpretación simultánea',
        titleIt: 'sistemi per la interpretazione simultanea',
        // description: no longer than 155 characters
        descriptionEs: 'Un servicio de pago por uso para eventos, reuniones, seminarios y conferencias.',
        descriptionIt: 'Un servizio pay per use per eventi, meeting, seminari e conferenze.',
        datePublished: '2017-11-12T10:00:00+00:00',
        dateModified: '2025-01-21T10:00:00+00:00'
      },
      'home': {
        meta: {
          title: 'simultaneous translation systems',
          description: 'A pay-per-use service for events, meetings, seminars, and conferences.',
          'twitter:card': 'summary_large_image',
          'twitter:image:alt': 'An image showing a prism getting and reflecting light rays in foreground with a conference hall during a liveevent in background'
        },
        props: {
          'og:image': 'https://ablioconference.com/media/home-animation-end.jpg',
          'og:type': 'website',
          'og:url': 'https://ablioconference.com/home'
        },
        enOnly: false,
        titleEs: 'sistemas de interpretación simultánea',
        titleIt: 'sistemi per la interpretazione simultanea',
        descriptionEs: 'Un servicio de pago por uso para eventos, reuniones, seminarios y conferencias.',
        descriptionIt: 'Un servizio pay per use per eventi, meeting, seminari e conferenze.',
        datePublished: '2017-11-12T10:00:00+00:00',
        dateModified: '2023-05-08T10:00:00+00:00'
      },
      'ablioconference': {
        meta: {
          title: 'AblioConference',
          description: 'AblioConference is a complete simultaneous interpretation system for live conferences and events.',
          'twitter:card': 'summary_large_image',
          'twitter:image:alt': 'A panorama image showing a conference hall during a live event'
        },
        props: {
          'og:image': 'https://ablioconference.com/images/ablioconference/ablioconference.jpg',
          'og:type': 'article',
          'og:url': 'https://ablioconference.com/ablioconference'
        },
        enOnly: false,
        titleEs: 'AblioConference',
        titleIt: 'AblioConference',
        descriptionEs: 'AblioConference es un sistema completo de interpretación simultánea para conferencias y eventos en vivo.',
        descriptionIt: 'AblioConference è un sistema completo per la interpretazione simultanea di eventi e conferenze dal vivo.',
        datePublished: '2018-07-12T10:00:00+00:00',
        dateModified: '2021-08-31T10:00:00+00:00'
      },
      'ablioconference-educational': {
        meta: {
          title: 'AblioConference Educational',
          description: 'AblioConference simultaneous interpretation tool for educational institutions, students, and interpreters.',
          'twitter:card': 'summary_large_image',
          'twitter:image:alt': 'An image showing the abblioconference logo'
        },
        props: {
          'og:image': 'https://ablioconference.com/images/brand/logo-512x69.png',
          'og:type': 'article',
          'og:url': 'https://ablioconference.com/ablioconference-educational'
        },
        enOnly: false,
        titleEs: 'AblioConference Educational',
        titleIt: 'AblioConference Educational',
        descriptionEs: 'Herramienta de interpretación simultánea AblioConference para instituciones educativas, estudiantes e intérpretes.',
        descriptionIt: 'AblioConference, strumenti di interpretazione simultanea per istituti di formazione linguistica, studenti e interpreti.',
        datePublished: '2018-11-26T10:00:00+00:00',
        dateModified: '2020-11-07T10:00:00+00:00'
      },
      'about': {
        meta: {
          title: 'About Us',
          description: 'Using state-of-the-art IT & telecommunication technologies, Ablio makes language interpretation services easily.',
          'twitter:card': 'summary_large_image',
          'twitter:image:alt': 'An image showing the abblioconference logo'
        },
        props: {
          'og:image': 'https://ablioconference.com/images/brand/logo-512x69.png',
          'og:type': 'article',
          'og:url': 'https://ablioconference.com/about'
        },
        enOnly: false,
        titleEs: 'Sobre nosotros',
        titleIt: 'Chi siamo',
        descriptionEs: 'Utilizando las últimas tecnologías de IT y telecomunicaciones, Ablio hace que los servicios de interpretación de idiomas estén fácilmente disponibles.',
        descriptionIt: 'Utilizzando le più avanzate tecnologie IT e di telecomunicazione, Ablio rende i servizi di interpretariato linguistico facilmente disponibili.',
        datePublished: '2018-02-07T10:00:00+00:00',
        dateModified: '2019-09-23T10:00:00+00:00'
      },
      'advantages': {
        meta: {
          title: 'Advantages',
          description: 'A simultaneous interpretation system is normally required whenever an event’s speaker or audience don’t all speak the same language.',
          'twitter:card': 'summary_large_image',
          'twitter:image:alt': 'An image showing the abblioconference logo'
        },
        props: {
          'og:image': 'https://ablioconference.com/images/brand/logo-512x69.png',
          'og:type': 'article',
          'og:url': 'https://ablioconference.com/advantages'
        },
        enOnly: false,
        titleEs: 'Ventajas',
        titleIt: 'Vantaggi',
        descriptionEs: 'Normalmente se requiere un sistema de interpretación simultánea cuando el orador o la audiencia de un evento no hablan el mismo idioma.',
        descriptionIt: 'Ogni volta che l’oratore e il pubblico di un evento non parlano la stessa lingua è normalmente richiesto un sistema di interpretazione simultanea.',
        datePublished: '2018-09-04T10:00:00+00:00',
        dateModified: '2020-01-08T10:00:00+00:00'
      },
      'contact': {
        meta: {
          title: 'Contacts',
          description: 'Contact us via phone or email. Our offices are located in Rome and Boca Raton, FL.',
          'twitter:card': 'summary_large_image',
          'twitter:image:alt': 'An image showing the abblioconference logo'
        },
        props: {
          'og:image': 'https://ablioconference.com/images/you-are-connected.png',
          'og:type': 'website',
          'og:url': 'https://ablioconference.com/contact'
        },
        enOnly: false,
        titleEs: 'Contáctenos',
        titleIt: 'Contatti',
        descriptionEs: 'El sistema de gestión completo de interpretación simultánea para conferencias y eventos.',
        descriptionIt: 'Il sistema di gestione completo della interpretazione simultanea per conferenze ed eventi.',
        datePublished: '2018-02-07T10:00:00+00:00',
        dateModified: '2020-07-30T10:00:00+00:00'
      },
      'early-adopters-program': {
        meta: {
          title: 'Early Adopters Program',
          description: 'Join our community of early adopters and beta testers. Be the first to discover and use AblioConference and reap its benefits.',
          'twitter:card': 'summary_large_image',
          'twitter:image:alt': 'An image showing the abblioconference logo'
        },
        props: {
          'og:image': 'https://ablioconference.com/images/brand/logo-512x69.png',
          'og:type': 'article',
          'og:url': 'https://ablioconference.com/early-adopters-program'
        },
        enOnly: false,
        titleEs: 'Programa de Adopción Temprana',
        titleIt: 'Programma Primi Utilizzatori',
        descriptionEs: 'Únase a nuestra comunidad de usuarios iniciales y beta testers. Sé el primero en descubrir y utilizar una conferencia informal y cosechar sus beneficios.',
        descriptionIt: 'Unisciti al gruppo dei primi utenti e dei beta testers. Sii il primo a scoprire e a usare AblioConference e approfitta dei suoi vantaggi.',
        datePublished: '2018-03-15T10:00:00+00:00',
        dateModified: '2019-11-02T10:00:00+00:00'
      },
      /* TODO: https://ablioconference.com/events-organizers
      'events-organizers': {
        meta: {
          title: 'Events Organizers',
          description: 'events-organizers.',
          'twitter:card': 'summary_large_image',
          'twitter:image:alt': 'An image showing the abblioconference logo'
        },
        props: {
          'og:image': 'https://ablioconference.com/images/brand/logo-512x69.png',
          'og:type': 'article',
          'og:url': 'https://ablioconference.com/events-organizers'
        },
        enOnly: false,
        titleEs: 'Events Organizers',
        titleIt: 'Events Organizers',
        descriptionEs: 'events-organizers.',
        descriptionIt: 'events-organizers.',
        datePublished: '20yy-MM-ddT10:00:00+00:00',
        dateModified: '2020-02-02T10:00:00+00:00'
      },
      */
      'faq': {
        meta: {
          title: 'Frequently Asked Questions',
          description: 'Answers to the most common questions.',
          'twitter:card': 'summary_large_image',
          'twitter:image:alt': 'An image showing the abblioconference logo'
        },
        props: {
          'og:image': 'https://ablioconference.com/images/brand/logo-512x69.png',
          'og:type': 'article',
          'og:url': 'https://ablioconference.com/faq'
        },
        enOnly: false,
        titleEs: 'Preguntas frecuentes',
        titleIt: 'Domande ricorrenti',
        descriptionEs: 'Respuestas a las preguntas más comunes.',
        descriptionIt: 'Risposte alle domande più frequenti.',
        datePublished: '2018-03-30T10:00:00+00:00',
        dateModified: '2019-06-25T10:00:00+00:00'
      },
      'how-it-works': {
        meta: {
          title: 'How it all works',
          description: 'Ablioconference is a complete simultaneous interpretation system for live conferences and events.',
          'twitter:card': 'summary_large_image',
          'twitter:image:alt': 'An image showing the abblioconference logo'
        },
        props: {
          'og:image': 'https://ablioconference.com/images/brand/logo-512x69.png',
          'og:type': 'article',
          'og:url': 'https://ablioconference.com/how-it-works'
        },
        enOnly: false,
        titleEs: 'Cómo funciona',
        titleIt: 'Come funziona',
        descriptionEs: 'AblioConference es un sistema completo de interpretación simultánea para conferencias y eventos en vivo.',
        descriptionIt: 'AblioConference è un sistema completo per la interpretazione simultanea per conferenze ed eventi dal vivo.',
        datePublished: '2018-09-04T10:00:00+00:00',
        dateModified: '2021-09-03T10:00:00+00:00'
      },
      'language-service-providers': {
        meta: {
          title: 'AblioConference for interpreters',
          description: 'This page is dedicated to interpreters who will always be the foundation of our activities and to whom we would like to introduce ourselves.',
          'twitter:card': 'summary_large_image',
          'twitter:image:alt': 'An image showing the abblioconference logo'
        },
        props: {
          'og:image': 'https://ablioconference.com/images/brand/logo-512x69.png',
          'og:type': 'article',
          'og:url': 'https://ablioconference.com/language-service-providers'
        },
        enOnly: false,
        titleEs: 'AblioConference para intérpretes',
        titleIt: 'AblioConference per interpreti',
        descriptionEs: 'Esta página está dedicada a intérpretes que siempre serán la base de nuestras actividades y a quienes nos gustaría presentarnos.',
        descriptionIt: 'Questa pagina è dedicata agli interpreti, che costituiranno sempre le fondamenta delle nostre attività, ai quali vogliamo innanzitutto presentarci.',
        datePublished: '2018-03-15T10:00:00+00:00',
        dateModified: '2021-07-08T10:00:00+00:00'
      },
      'multilingual-web-conferences': {
        meta: {
          title: 'Virtual and hybrid events',
          description: 'AblioConference provides simultaneous interpretation services to any video conference with the desired number of languages and in any language.',
          'twitter:card': 'summary_large_image',
          'twitter:image:alt': 'An image showing the abblioconference logo'
        },
        props: {
          'og:image': 'https://ablioconference.com/images/multilingual-web-conferences/multilingual-web-conferences-how-it-works-organizers.png',
          'og:type': 'article',
          'og:url': 'https://ablioconference.com/multilingual-web-conferences'
        },
        enOnly: false,
        titleEs: 'Conferencias web multilingües',
        titleIt: 'Conferenze Web multilingue',
        descriptionEs: 'AblioConference proporciona servicios de interpretación simultánea a cualquier videoconferencia con el número deseado de idiomas y en cualquier idioma.',
        descriptionIt: 'AblioConference fornisce servizi di interpretazione simultanea a qualsiasi videoconferenza con il numero desiderato di lingue e in qualsiasi lingua.',
        datePublished: '2020-04-10T10:00:00+00:00',
        dateModified: '2021-09-03T10:00:00+00:00'
      },
      'password-forgot': {
        meta: {
          title: 'Reset your password',
          description: 'AblioConference is a complete simultaneous interpretation system for live conferences and events.',
          'twitter:card': 'summary_large_image',
          'twitter:image:alt': 'AblioConference logo'
        },
        props: {
          'og:image': 'https://ablioconference.com/modules/core/img/brand/logo.png',
          'og:type': 'website',
          'og:url': 'https://ablioconference.com/password-forgot'
        },
        enOnly: false,
        titleEs: 'Restablecer su contraseña',
        titleIt: 'Reimposta la tua password',
        descriptionEs: 'Si tiene dificultades para iniciar sesión en ablioconference.com, póngase en contacto con el Mesa de ayuda.',
        descriptionIt: 'Se non rieasci ad entrare in ablioconference.com contatta il nostro HelpDesk.',
        datePublished: '2019-03-20T10:00:00+00:00',
        dateModified: '2019-03-18T10:00:00+00:00'
      },
      'pricing': {
        meta: {
          title: 'Pricing',
          description: 'Available Service Plans',
          'twitter:card': 'summary_large_image',
          'twitter:image:alt': 'An image showing the abblioconference logo'
        },
        props: {
          'og:image': 'https://ablioconference.com/images/brand/logo-512x69.png',
          'og:type': 'article',
          'og:url': 'https://ablioconference.com/pricing'
        },
        enOnly: false,
        titleEs: 'Precios',
        titleIt: 'Definizione dei prezzi',
        descriptionEs: 'Planes de servicio disponibles.',
        descriptionIt: 'Piani di Servizio Disponibili.',
        datePublished: '2018-03-30T10:00:00+00:00',
        dateModified: '2021-08-31T10:00:00+00:00'
      },
      'privacy-policy': {
        meta: {
          title: 'Privacy Policy',
          description: 'Information on how we collect, use, protect or otherwise handle your Personally Identifiable Information in accordance with our website.',
          'twitter:card': 'summary_large_image',
          'twitter:image:alt': 'An image showing the abblioconference logo'
        },
        props: {
          'og:image': 'https://ablioconference.com/images/brand/logo-512x69.png',
          'og:type': 'article',
          'og:url': 'https://ablioconference.com/privacy-policy'
        },
        enOnly: false,
        titleEs: 'Política de privacidad',
        titleIt: 'Norme sulla Privacy',
        descriptionEs: 'Nuestra política de privacidad está diseñada para ayudarlo a comprender qué información recopilamos y cómo usamos y compartimos esa información.',
        descriptionIt: 'Le nostre Norme sulla Privacy sono ideate per aiutarti a capire quali informazioni raccogliamo e come usiamo e condividiamo tali informazioni.',
        datePublished: '2018-02-07T10:00:00+00:00',
        dateModified: '2021-03-17T10:00:00+00:00'
      },
      /* TODO: https://ablioconference.com/quality-standards
      'quality-standards': {
        meta: {
          title: 'quality-standards',
          description: 'quality-standards.',
          'twitter:card': 'summary_large_image',
          'twitter:image:alt': 'An image showing the abblioconference logo'
        },
        props: {
          'og:image': 'https://ablioconference.com/images/brand/logo-512x69.png',
          'og:type': 'article',
          'og:url': 'https://ablioconference.com/quality-standards'
        },
        enOnly: false,
        titleEs: 'quality-standards',
        titleIt: 'quality-standards',
        descriptionEs: 'quality-standards.',
        descriptionIt: 'quality-standards.',
        datePublished: '20yy-MM-ddT10:00:00+00:00',
        dateModified: '2020-02-02T10:00:00+00:00'
      },
      */
      /* TODO: #https://ablioconference.com/resellers-network
      'resellers-network': {
        meta: {
          title: 'resellers-network',
          description: 'resellers-network.',
          'twitter:card': 'summary_large_image',
          'twitter:image:alt': 'An image showing the abblioconference logo'
        },
        props: {
          'og:image': 'https://ablioconference.com/images/brand/logo-512x69.png',
          'og:type': 'article',
          'og:url': 'https://ablioconference.com/resellers-network'
        },
        enOnly: false,
        titleEs: 'resellers-network',
        titleIt: 'resellers-network',
        descriptionEs: 'resellers-network.',
        descriptionIt: 'resellers-network.',
        datePublished: '20yy-MM-ddT10:00:00+00:00',
        dateModified: '2020-02-02T10:00:00+00:00'
      },
      */
      'resellers-program': {
        meta: {
          title: 'Resellers Program',
          description: 'Expand your business and increase your profits by using the potential of AblioConference and its Reseller Program.',
          'twitter:card': 'summary_large_image',
          'twitter:image:alt': 'An image showing the abblioconference logo'
        },
        props: {
          'og:image': 'https://ablioconference.com/images/brand/logo-512x69.png',
          'og:type': 'article',
          'og:url': 'https://ablioconference.com/resellers-program'
        },
        enOnly: false,
        titleEs: 'Programa de revendedores',
        titleIt: 'Programma Rivenditori',
        descriptionEs: 'Expanda su negocio y aumente sus ganancias utilizando el potencial de AblioConference y su Programa de Revendedores.',
        descriptionIt: 'Espandi il tuo giro di affari e aumenta i tuoi profitti usando il potenziale di AblioConference e del suo Programma Rivenditori.',
        datePublished: '2018-09-04T10:00:00+00:00',
        dateModified: '2021-09-28T10:00:00+00:00'
      },
      'signin': {
        meta: {
          title: 'Sign In',
          description: 'Sign in using your account.',
          'twitter:card': 'summary_large_image',
          'twitter:image:alt': 'An image showing the abblioconference logo'
        },
        props: {
          'og:image': 'https://ablioconference.com/images/brand/logo-512x69.png',
          'og:type': 'website',
          'og:url': 'https://ablioconference.com/signin'
        },
        enOnly: false,
        titleEs: 'Iniciar sesión',
        titleIt: 'Accedi',
        descriptionEs: 'Inicie sesión con su cuenta.',
        descriptionIt: 'Accedi utilizzando il tuo account.',
        datePublished: '2017-11-12T10:00:00+00:00',
        dateModified: '2021-09-22T10:00:00+00:00'
      },
      'signup': {
        meta: {
          title: 'Sign Up',
          description: 'Customer Sign Up.',
          'twitter:card': 'summary_large_image',
          'twitter:image:alt': 'An image showing the abblioconference logo'
        },
        props: {
          'og:image': 'https://ablioconference.com/images/brand/logo-512x69.png',
          'og:type': 'website',
          'og:url': 'https://ablioconference.com/signup'
        },
        enOnly: false,
        titleEs: 'Registrarse',
        titleIt: 'Iscriviti',
        descriptionEs: 'Registro del cliente.',
        descriptionIt: 'Registrazione clienti.',
        datePublished: '2017-11-12T10:00:00+00:00',
        dateModified: '2020-11-07T10:00:00+00:00'
      },
      'sitemap': {
        meta: {
          title: 'Site Map',
          description: 'Hierarchical list of public views, links, and pages for ablioconference.com.',
          'twitter:card': 'summary_large_image',
          'twitter:image:alt': 'An image showing the abblioconference logo'
        },
        props: {
          'og:image': 'https://ablioconference.com/images/brand/logo-512x69.png',
          'og:type': 'website',
          'og:url': 'https://ablioconference.com/sitemap'
        },
        enOnly: false,
        titleEs: 'Mapa del sitio',
        titleIt: 'Mappa del sito',
        descriptionEs: 'Lista jerárquica de vistas públicas, enlaces y páginas para ablioconference.com.',
        descriptionIt: 'Elenco gerarchico di viste, collegamenti e pagine pubbliche per ablioconference.com.',
        datePublished: '2018-02-16T10:00:00+00:00',
        dateModified: '2025-01-21T10:00:00+00:00'
      },
      'technical-service-providers': {
        meta: {
          title: 'AblioConference for technical service providers',
          description: 'Traditional equipment has been based upon radio InfraRed technologies for many years.',
          'twitter:card': 'summary_large_image',
          'twitter:image:alt': 'An image showing the abblioconference logo'
        },
        props: {
          'og:image': 'https://ablioconference.com/images/brand/logo-512x69.png',
          'og:type': 'article',
          'og:url': 'https://ablioconference.com/technical-service-providers'
        },
        enOnly: false,
        titleEs: 'AblioConference para proveedores de servicios técnicos',
        titleIt: 'AblioConference per i fornitori di servizi tecnici',
        descriptionEs: 'El equipamiento tradicional se ha basado en tecnologías de radio InfraRojo durante muchos años.',
        descriptionIt: 'Gli impianti tradizionali si basano da molti anni su tecnologie radio a Infrarossi.',
        datePublished: '2018-09-04T10:00:00+00:00',
        dateModified: '2019-02-27T10:00:00+00:00'
      },
      'terms-and-conditions': {
        meta: {
          title: 'Terms of Use',
          description: 'These terms of use apply to products and services provided by Ablio.',
          'twitter:card': 'summary_large_image',
          'twitter:image:alt': 'An image showing the abblioconference logo'
        },
        props: {
          'og:image': 'https://ablioconference.com/images/brand/logo-512x69.png',
          'og:type': 'article',
          'og:url': 'https://ablioconference.com/terms-and-conditions'
        },
        enOnly: true,
        titleEs: 'Términos y Condiciones',
        titleIt: 'Rermini e Condizioni',
        descriptionEs: 'These terms of use apply to products and services provided by Ablio.',
        descriptionIt: 'These terms of use apply to products and services provided by Ablio.',
        datePublished: '2018-09-04T10:00:00+00:00',
        dateModified: '2020-07-30T10:00:00+00:00'
      }
      /* TODO: #https://ablioconference.com/tour
      'tour': {
        meta: {
          title: 'tour',
          description: 'tour.',
          'twitter:card': 'summary_large_image',
          'twitter:image:alt': 'An image showing the abblioconference logo'
        },
        props: {
          'og:image': 'https://ablioconference.com/images/brand/logo-512x69.png',
          'og:type': 'article',
          'og:url': 'https://ablioconference.com/tour'
        },
        enOnly: false,
        titleEs: 'tour',
        titleIt: 'sistemi per la interpretazione simultanea',
        descriptionEs: 'tour.',
        descriptionIt: 'tour.',
        datePublished: '20yy-MM-ddT10:00:00+00:00',
        dateModified: '2020-02-02T10:00:00+00:00'
      },
      */
      /* TODO: #https://ablioconference.com/venues
      'venues': {
        meta: {
          title: 'venues',
          description: 'venues.',
          'twitter:card': 'summary_large_image',
          'twitter:image:alt': 'An image showing the abblioconference logo'
        },
        props: {
          'og:image': 'https://ablioconference.com/images/brand/logo-512x69.png',
          'og:type': 'article',
          'og:url': 'https://ablioconference.com/venues'
        },
        enOnly: false,
        titleEs: 'venues',
        titleIt: 'venues',
        descriptionEs: 'venues.',
        descriptionIt: 'venues.',
        datePublished: '20yy-MM-ddT10:00:00+00:00',
        dateModified: '2020-02-02T10:00:00+00:00'
      }
      */
    };
  }

  // Main process
  updateSEOData(pageURL) {
    if (!pageURL) return;

    let lang = localStorage.getItem('userWebsiteLanguage') && (localStorage.getItem('userWebsiteLanguage').length === 2) ? localStorage.getItem('userWebsiteLanguage') : 'en';
    let pageName = pageURL.substring(1, pageURL.length);
    if (pageURL === '/') {
      pageName = 'home';
    } else {
      pageName = pageURL.substring(1, pageURL.length);
      pageName = pageName.split('?')[0];
      pageName = pageName.split('#')[0];
    }
    pageURL = pageName;
    let pageTitle = pageURL.replace(/\//gi, ' | ');
    pageName = pageName.replace(/\//gi, '-');
    let seoData = this.seoDataMap[pageName] || this.seoDataMap.allotherpages;
    if (!this.seoDataMap[pageName]) seoData.meta.title = pageTitle;

    let jsonldtext: any = {
      '@context': 'http://schema.org',
      'url': 'https://ablioconference.com' + (pageURL ? '/' + pageURL : '')
    };
    if (seoData.props['og:type'] === 'website') {
      jsonldtext['@type'] = 'Organization';
      jsonldtext.name = 'Ablio';
      jsonldtext.legalName = 'Ablio LLC';
      jsonldtext.logo = 'https://ablioconference.com/images/brand/logo-512x69.png';
      jsonldtext.foundingDate = '2014';
      jsonldtext.founders = [
        { '@type': 'Person', 'name': 'Giulio Monaco' },
        { '@type': 'Person', 'name': 'Jose Fresco Benaim' },
        { '@type': 'Person', 'name': 'Piergiorgio Fiandanese' }
      ];
      jsonldtext.contactPoint = [{
        '@type': 'ContactPoint',
        'telephone': '+1 805-601-8224',
        'contactType': 'customer service'
      }, {
        '@type': 'ContactPoint',
        'telephone': '+39 06 9480 7005',
        'contactType': 'customer service',
        'areaServed': ['IT'],
        'availableLanguage': ['Italian', 'English']
      }];
    } else {
      jsonldtext['@type'] = 'NewsArticle';
      jsonldtext.mainEntityOfPage = {
        '@type': 'WebPage',
        '@id': 'https://ablioconference.com' + (pageURL ? '/' + pageURL : '')
      };
      jsonldtext.author = {
        '@type': 'Organization',
        'name': 'Ablio'
      };
      jsonldtext.publisher = {
        '@type': 'Organization',
        'name': 'Ablio',
        'logo': {
          '@type': 'ImageObject',
          'url': 'https://ablioconference.com/images/brand/logo-512x69.png'
        }
      };
      jsonldtext.datePublished = (seoData && seoData.datePublished ? seoData.datePublished : '2017-11-12T10:00:00+00:00');
      jsonldtext.dateModified = (seoData && seoData.dateModified ? seoData.dateModified : '2020-01-13T10:00:00+00:00');
    }

    // jQuery check
    if (!!$) {
      let $html = $('html');
      if (!lang || (seoData.enOnly === true)) lang = 'en';
      $html.attr('lang', lang);
      let $head = $('head');

      $head.find('meta[property="twitter:site"]').remove();
      $head.prepend('<meta property="twitter:site" content="@abliodotcom">');
      $head.find('meta[property="og:locale"]').remove();

      if (lang === 'es') {
        $head.prepend('<meta property="og:locale" content="es_ES">');
        seoData.meta.title = seoData.titleEs;
        seoData.meta.description = seoData.descriptionEs;
      //} else if (lang === 'fr') {
      //  $head.prepend('<meta property="og:locale" content="fr_FR">');
      //  seoData.meta.title = seoData.titleFr;
      //  seoData.meta.description = seoData.descriptionFr;
      } else if (lang === 'it') {
        $head.prepend('<meta property="og:locale" content="it_IT">');
        $head.find('meta[property="twitter:site"]').remove();
        $head.prepend('<meta property="twitter:site" content="@ablio_italia">');
        if (jsonldtext['@type'] === 'Organization') {
          jsonldtext.legalName = 'Ablio S.r.l.';
        }
        seoData.meta.title = seoData.titleIt;
        seoData.meta.description = seoData.descriptionIt;
      //} else if (lang === 'ru') {
      //  $head.prepend('<meta property="og:locale" content="ru_RU">');
      //  seoData.meta.title = seoData.titleRu;
      //  seoData.meta.description = seoData.descriptionRu;
      } else {
        $head.prepend('<meta property="og:locale" content="en_US">');
      }

      for (var mkey in seoData.meta) {
        if (mkey === 'title') {
          if (seoData.meta[mkey].length > 51) seoData.meta[mkey] = seoData.meta[mkey].substring(0,49) + '…';
          $head.find('title').text('AblioConference :: ' + seoData.meta[mkey]);
          $head.find('meta[property="og:title"]').remove();
          $head.prepend('<meta property="og:title" content="AblioConference :: ' + seoData.meta[mkey] + '">');
        } else if (mkey === 'description') {
          if (seoData.meta[mkey].length > 155) seoData.meta[mkey] = seoData.meta[mkey].substring(0,153) + '…';
          $head.find('meta[name="description"]').remove();
          $head.prepend('<meta name="description" content="' + seoData.meta[mkey] + '">');
          $head.find('meta[property="og:description"]').remove();
          $head.prepend('<meta property="og:description" content="' + seoData.meta[mkey] + '">');
          if (jsonldtext['@type'] === 'NewsArticle') {
            jsonldtext.headline = seoData.meta[mkey];
            if (jsonldtext.headline.length > 110) jsonldtext.headline = jsonldtext.headline.substring(0,108) + '…';
            jsonldtext.description = seoData.meta[mkey];
          }
        } else {
          $head.find('meta[name="' + mkey + '"]').remove();
          $head.prepend('<meta name="' + mkey + '" content="' + seoData.meta[mkey] + '">');
        }
      }

      seoData.props['og:url'] = 'https://ablioconference.com' + (pageURL ? '/' + pageURL : '');
      for (var pkey in seoData.props) {
        $head.find('meta[property="' + pkey + '"]').remove();
        $head.prepend('<meta property="' + pkey + '" content="' + seoData.props[pkey] + '">');

        if ((pkey === 'og:image') && (jsonldtext['@type'] === 'NewsArticle')) {
          jsonldtext.image = seoData.props[pkey];
        }
      }

      $head.find('script[type="application/ld+json"]').remove();
      var el = document.createElement('script');
      el.type = 'application/ld+json';
      el.text = JSON.stringify(jsonldtext);
      $head.append(el);
    }
  }
}
