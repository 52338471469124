export const environment = {
  apiUrl: 'https://dev.ablioconference.com/api',
  apiUrlUnsecure: 'http://dev.ablioconference.com:4000/api',
  broadcasterUrl: 'https://local.ablioconference.com',
  enableProdMode: true,
  intercomAppId: 'o8xl0puz',
  kurentoHost: 'ws://ec2-18-203-39-157.eu-west-1.compute.amazonaws.com:8888/kurento',
  localKurentoHost: 'ws://local.ablioconference.com:8888/kurento',
  localMediaHost: 'http://local.ablioconference.com',
  mediaHost: 'http://ec2-18-203-39-157.eu-west-1.compute.amazonaws.com',
  name: 'development',
  serverUrl: 'https://dev.ablioconference.com',
  stripePublicKey: 'pk_test_tS1muOIkq4gYP94M9FDcP5UU',
  wsHostname: 'dev.ablioconference.com'
};
