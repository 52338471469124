import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { ChatThread } from '../../../../models/ChatThread';
import { User } from '../../../../models/User';
import { ChatService } from '../../../../services/chat/chat.service';
import { UserService } from '../../../../services/user/user.service';
// NOT USED: import { FlashMessagesService } from '../../../../services/util/flash-messages.service';

@Component({
  selector: 'app-chat',
  templateUrl: './chat.component.html',
  styleUrls: ['./chat.component.css']
})
export class ChatComponent implements OnInit, OnDestroy {
  user: User;
  messageText: string;
  showChat: boolean;
  chatThreads = new Map<string, ChatThread>();
  selectedChatThread: ChatThread;
  unreadMessages: boolean;
  reconnecting: boolean;
  private subscriptions = new Subscription();

  constructor(
    private chatServ: ChatService,
    private userServ: UserService//,
    // NOT USED: private flashMessagesServ: FlashMessagesService
  ) {
  }

  ngOnInit() {
    this.subscriptions.add(this.chatServ.ready.subscribe((isReady) => {
      if (!isReady) {
        this.reconnecting = true;
        return;
      }

      this.reconnecting = false;
      this.subscriptions.add(this.chatServ.chatThreadsChange.subscribe((data) => {
        this.chatThreads = data;
        this.unreadMessages = false;
        this.chatThreads.forEach( chatThread => {
          if (chatThread.unreadMessage) {
            this.unreadMessages = true;
            return;
          }
        })
      }));

      this.subscriptions.add(this.chatServ.newMessageChange.subscribe((chatThread) => {
        if (!chatThread) {
          return;
        }

        if (chatThread.unreadMessage && (!this.showChat || (this.selectedChatThread !== chatThread))) {
          // if not reading chat thread
          this.unreadMessages = true;
        } else {
          chatThread.unreadMessage = false;
        }
      }));

      this.subscriptions.add(this.userServ.userChange.subscribe((user) => {
        this.user = user;
        this.chatServ.joinGroups();
      }));
    }));
  }

  setUnreadMessages(chatThread: ChatThread, value: boolean) {
    chatThread.unreadMessage = value;
    chatThread.manyUnreadMessages = value;
  }

  showHideChat() {
    this.showChat = !this.showChat;
    this.unreadMessages = false;
    if (this.selectedChatThread) {
      this.setUnreadMessages(this.selectedChatThread, false);
    }
  }

  selectChatSocket(chatThread) {
    this.selectedChatThread = chatThread.value;
    this.selectedChatThread.id = chatThread.key;
    this.setUnreadMessages(this.selectedChatThread, false);
  }

  sendMessageText() {
    this.chatServ.sendChatMessage(this.messageText, this.selectedChatThread);
    this.messageText = '';
    return false;
  }

  ngOnDestroy() {
    // send user offline to all groups to notify other users
    this.chatServ.userOffline();
    this.subscriptions.unsubscribe();
  }
}
